// You can choose any icon from this list: https://react-icons.github.io/react-icons
import {
  FaAngleDown,
  FaEnvelope,
  FaInstagram,
  FaWhatsapp
} from 'react-icons/fa';

import {
  SiShopee
} from "react-icons/si";

// This is the color list you can use for now: https://mui.com/material-ui/customization/color/
import {
  amber,
  orange,
} from '@mui/material/colors';

// @TODO: Create colorset for light and dark modes.
// @TODO: Properly use button and icon colors or maybe even ditch out
const colors = {
  "mode": "dark",
  "primary": amber,
  "secondary": orange,
  "button": amber,
  "icon": orange,
}

const routes = {
  "email": "mailto:contato@artefeito.com.br",
  "instagram": "https://instagram.com/artefeito.com.br",
  "shopee": "https://shopee.com.br/matocraft.com.br",
  "sourcecode": "https://gitlab.com/osouza.de/bah-link-tri",
  "whatsapp": "",
}

const icons = [
  {
    "tooltip": "Our pics's place",
    "icon": <FaInstagram />,
    "route": "instagram"
  },
  {
    "tooltip": "Send us a whatsapp message!",
    "icon": <FaWhatsapp />,
    "route": "whatsapp",
    "hidden": false
  },
  {
    "tooltip": "Send us a e-mail!",
    "icon": <FaEnvelope />,
    "route": "email",
  }
]

// @TODO: maybe create a grouping option?

const items = [
  {
    "title": "Quem é a ArteFeito?",
    "content": "<p>Nossas criações inspiram além de brilhar os olhos e aquecer corações.<br/>Temos um catálogo de produtos além de produzirmos sob encomenda: artigos de decoração, jóias e acessórios.<br/>Com matéria-prima natural (madeira, cobre, pedras preciosas) e também impressão 3D.</p><p>Visite nosso Instagram para conferir nosso trabalho!</p><br/><small style='font-style:italic; float: right'>Artefeito - Artesanato feito arte!</small><br/>",
    "icon": <FaAngleDown />,
    "type": "accordion",
    "expanded": true
  },
  {
    "title": "Nosso Insta!",
    "content": "Artefeito's Instagram!",
    "icon": <FaInstagram />,
    "route": "instagram",
    "type": "link",
  },
  {
    "title": "Loja na Shopee",
    "content": "Artefeito's Shopee store.",
    "icon": <SiShopee />,
    "route": "shopee",
    "type": "link",
  }
]

const config = {
  "avatar": "",
  "title": "Artefeito",
  colors,
  icons,
  items,
  routes
}

export default config
